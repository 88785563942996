<template>
  <div>
    <x-header>
      <!-- banner -->
      <img slot="headerImage" src="../../assets/aboutUs/banner.jpg" alt="" />
      <!-- 标题 -->
      <h1 slot="headerTitle">
        <img src="../../assets/aboutUs/aboutUsLogo.png" alt="" />
         {{ $store.state.navShow == true ? "全球客户的忠实伙伴" : "Loyal partner to Global Customers" }}
      </h1>
    </x-header>

    <!-- 企业介绍 -->
    <div class="introduce">
      <div class="aboutUs_intro">
        <div class="modeTitleMain">
          <h2 class="h2Title">
            {{ $store.state.navShow == true ? "企业介绍" : "Company profile" }}
          </h2>
          <div class="modeMain">
            <div class="modeMain_l">
              <img
                class="modeMain_l_image"
                src="../../assets/aboutUs/intro_img.png"
                alt=""
              />
              <div
                class="modeMain_l_info"
                v-html="$store.state.navShow == true ? usContent : usContentE"
              ></div>
            </div>
            <div class="modeMain_r">
              <img src="../../assets/aboutUs/intro_rightImg.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- 企业目标 -->
      <div
        class="aboutUs_goal"
        :style="$store.state.navShow == true ? '' : '    bottom: -240px;'"
      >
        <h2 class="h2Title">
          {{ $store.state.navShow == true ? "企业目标" : "Enterprise's goal" }}
        </h2>
        <div class="goal_main">
          <div
            class="main_items"
            v-for="item in $store.state.navShow == true
              ? globalArr
              : globalArrE"
            :key="item"
            data-aos="zoom-in-up"
          >
            <img :src="item.globalImgIcon" alt="" />
            <p>{{ item.globalTitle }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 每天都极度认真 -->
    <div
      class="everyOne"
      :style="$store.state.navShow == true ? '' : 'margin-top: 350px'"
    >
      <div class="everyOne_l" data-aos="fade-right">
        <img src="../../assets/aboutUs/yuangong01.jpg" alt="" />
      </div>
      <div class="everyOne_r">
        <div class="right_content">
          <h1 data-aos="fade-left">
            {{
              $store.state.navShow == true
                ? "每一天都极度认真"
                : "Extremely conscientious every day"
            }}
          </h1>
          <p data-aos="fade-left">
            {{
              $store.state.navShow == true
                ? "MG集团在俄罗斯、白俄罗斯、德国、英国拥有本士国际贸易服务团队，其中俄罗斯境内分公司5家，俄方员工占95%"
                : "We have international trade service team in Russia, Belarus, Germany, UK, 5 branch offices in Russia with 95% of Russian employees."
            }}
          </p>
          <div class="numDiv">
            <div class="numDiv_item">
              <span>
                <countTo :startVal="0" :endVal="5" :duration="3000"></countTo>
              </span>
              <time>{{
                $store.state.navShow == true ? "家" : ""
              }}</time>
              <p>
                {{
                  $store.state.navShow == true
                    ? "俄罗斯境内分公司"
                    : "branch offices in Russia"
                }}
              </p>
            </div>

            <div class="numDiv_item">
              <span>
                <countTo :startVal="0" :endVal="95" :duration="3000"></countTo>
              </span>
              <time>%</time>
              <p>
                {{
                  $store.state.navShow == true
                    ? "俄方员工占比"
                    : "of Russian employees"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="personnel">
      <div class="personnel_l"></div>
      <div class="personnel_r">
        <div class="personnel_r_item">
          <img src="../../assets/aboutUs/yuangong02.jpg" alt="" />
        </div>
        <div class="personnel_r_item">
          <img src="../../assets/aboutUs/yuangong03.jpg" alt="" />
        </div>
        <div class="personnel_r_item">
          <img src="../../assets/aboutUs/yuangong04.jpg" alt="" />
        </div>
        <div class="personnel_r_item">
          <img src="../../assets/aboutUs/yuangong05.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 头尾导入
import xHeader from '../../components/header.vue'
import countTo from 'vue-count-to'
export default {
  components: {
    // 使用/注册引入的组件 components
    xHeader,
    countTo
  },
  data () {
    return {
      globalArr: [
        {
          globalImgIcon: require('../../assets/aboutUs/globalIcon1.png'),
          globalTitle: '全球客户的忠实伙伴'
        },
        {
          globalImgIcon: require('../../assets/aboutUs/globalIcon2.png'),
          globalTitle: '全球行业的行为典范'
        },
        {
          globalImgIcon: require('../../assets/aboutUs/globalIcon3.png'),
          globalTitle: '全球员工的最佳雇主'
        },
        {
          globalImgIcon: require('../../assets/aboutUs/globalIcon4.png'),
          globalTitle: '全球公众的信赖品牌'
        }
      ],
      globalArrE: [
        {
          globalImgIcon: require('../../assets/aboutUs/globalIcon1.png'),
          globalTitle: 'Loyal partner of global customers'
        },
        {
          globalImgIcon: require('../../assets/aboutUs/globalIcon2.png'),
          globalTitle: 'A role model for the global industry'
        },
        {
          globalImgIcon: require('../../assets/aboutUs/globalIcon3.png'),
          globalTitle: 'Best Employers for Global Employees'
        },
        {
          globalImgIcon: require('../../assets/aboutUs/globalIcon4.png'),
          globalTitle: 'A trusted brand for the global public'
        }
      ],
      usContent: 'Margin Group（简称：MG集团）总部位于俄罗斯圣彼得堡，是一家致力于为中俄、西欧的商贸企业提供国际物流、进出口贸易、报关清关、供应链等服务的跨国集团企业。在莫斯科、海参崴、白俄罗斯设立分公司，在中国上海、河北、海南、香港设立4家分公司。<br>MG集团在俄罗斯拥有成熟的国际物流资源，在中国市场拥有稳定的国际运输线路及多式联运经验，在中欧班列线路开拓、海运服务、空运服务、俄罗斯及欧洲国家的清关服务、境外陆运及配送服务、海外仓及国际贸易等方面形成了高质量特色服务及品牌。<br>MG集团凭借多年中俄供应链服务经验，专注于国际贸易与国际物流两大关键领域。“立足供应链，服务产业链，创造价值链”，MG将通过”物流带动贸易流通，贸易延伸物流价值”的运贸一体化服务能力，沿着产业链向上下游延伸，提供外贸交易流通、“站到站”“门到门”全程物流、供应链、信息咨询等全价值链流通服务，为全球客户提供优质便捷的采购供应及综合物流服务。', // 关于我们内容
      usContentE: 'Margin group (MG Group for short), headquartered in St. Petersburg, Russia, is a multinational enterprise committed to providing international logistics, import and export trade, customs clearance, supply chain and other services to commercial enterprises in China, Russia and Western Europe. We have set up branches in Moscow, Vladivostok and Belarus, and four branches in Shanghai, Hebei, Hainan and Hong Kong.We have mature international logistics resources in the Russia market and stable experience in international transportation routes and multimodal transport in the Chinese market.The company formed high-quality characteristic services and brands in the development of China Europe train routes, maritime services, air transport services, customs clearance services in Russia and European countries, overseas land transportation and distribution services, overseas warehouses and international trade.With years of experience in Sino-Russian supply chain services, we focus on two key areas: International Trade and international logistics. "Based on the supply chain, serving the industrial chain, and creating the value chain". Mg will extend up and down the industrial chain through the transportation and trade integration service capability of "logistics drives trade circulation, and trade extends logistics value", providing foreign trade transaction circulation, "station to station", "door to door" whole process logistics, supply chain, information consulting and other full value chain circulation services, and provide high-quality and convenient procurement, supply and comprehensive logistics services for global customers.'
    }
  },
  mounted () {
    /**
     * 关于我们seo与企业介绍
     * 肖明霞
     * 2021-11-23
     */
    // this.$axios.get('/api/About/index').then((res) => {
    //   if (res.data.code === 1) {
    //     this.usContent = res.data.data.content // 企业介绍
    //   } else {
    //     this.$message({
    //       showClose: true,
    //       message: '企业介绍' + res.data.msg,
    //       type: 'error'
    //     })
    //   }
    // })
  }
}
</script>
<style lang="less" scoped>
.h2Title {
  text-align: left;
  color: #396eb4;
  height: 100px;
  line-height: 100px;
}
.introduce {
  position: relative;
  // background: orange;
  .aboutUs_intro {
    width: var(--width1200px);
    // width:1200px;
    // height: 650px;
    // background: orange;
    margin: 0px auto;
    margin-top: 110px;

    .modeTitleMain {
      // background: red;
      //内容
      .modeMain {
        width: 100%;
        //    height: 500px;
        //    background: orange;
        display: flex;
        justify-content: space-between;
        .modeMain_l {
          //    flex: 1;
          width: 650px;
          height: 100%;
          min-height: 24vw;
          //    background: green;
          line-height: 35px;
          position: relative;
          // 底部图片
          .modeMain_l_image {
            position: absolute;
            right: 0;
            bottom: 0;
          }
          .modeMain_l_info {
            text-indent: 32px;
            text-align: left;
            & > p {
              margin-bottom: 40px;
            }
          }
        }
        .modeMain_r {
          //    flex:2;
          //    background: pink;
        }
      }
    }
  }
  // 企业目标
  .aboutUs_goal {
    width: 1200px;
    height: 220px;
    // background: red;
    // margin:0 auto;
    position: absolute;
    bottom: -38px;
    left: 50%;
    transform: translateX(-50%);
    .goal_main {
      width: 920px;
      height: 150px;
      background: linear-gradient(270deg, #3676c9, #42be72);
      display: flex;
      align-items: center;
      .main_items {
        flex: 1;
        // background: orange;
        p {
          color: #fff;
        }
      }
    }
  }
}

// 每天都极度认真
.everyOne {
  width: 100%;
  height: 660px;
  background: #2d6aab;
  display: flex;
  overflow: hidden;
  margin-top: 175px;
  .everyOne_l {
    width: 1220px;
    // background: orange;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  .everyOne_r {
    width: 700px;
    // background: green;
    color: #fff;
    display: flex;
    // align-items: center;

    .right_content {
      width: 450px;
      // background: orange;
      text-align: left;
      margin-left: 65px;
      margin-top: 120px;
      & > p {
        margin: 40px 0 70px 0;
        line-height: 30px;
      }
      .numDiv {
        display: flex;
        // background: red;
        .numDiv_item {
          & > span {
            font-weight: 900;
            font-size: 40px;
          }
          time {
            font-size: 22px;
            margin-left: -5px;
          }
          & > p {
            font-size: 15px;
          }
          &:nth-child(1) {
            margin-right: 100px;
          }
        }
      }
    }
  }
}
.personnel {
  width: 100%;
  height: 230px;
  display: flex;
  // background: pink;
  position: relative;
  .personnel_l {
    // flex: 1;
    width: calc(100% - 1400px);
    // background: yellow;
  }
  .personnel_r {
    width: 1400px;
    // background: orange;
    display: flex;
    position: absolute;
    top: -50%;
    right: 0;
    .personnel_r_item {
      width: 350px;
      height: 210px;
      // background: red;
    }
  }
}
</style>
